import React from "react";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";

function HelpDialogContent() {
  const { navConfig } = useSelector((state) => state.navConfig);
  const { mailContact, techSupport } = navConfig.help;
  return (
    <>
      <Typography>
        Please click&nbsp;
        <a
          target="_blank"
          href={`${process.env.PUBLIC_URL}/resources/WV-INDUSTRY-TM.pdf`}
          rel="noreferrer"
        >
          here
        </a>
        &nbsp;to view the training material and
      </Typography>
      <Typography>
        Please click&nbsp;
        <a
          target="_blank"
          href="https://omc.wv.gov/patients/Documents/Application%20Roadmap_Approved%20by%20Comm%20w%20ACH%20INFO.pdf"
          rel="noreferrer"
        >
          here
        </a>
        &nbsp;to view the Patient Roadmap Guide.
      </Typography>
      <Typography>
        Please contact the WV Office of Medical Cannabis at&nbsp;
        <a href={`mailto:${mailContact}`} target="_top">
          {mailContact}
        </a>
        &nbsp;for Program Support.
        <br />
      </Typography>
      <Typography sx={{ mb: 0 }}>
        For Technical Support please contact us at this email address:{" "}
      </Typography>
      <ul>
        {techSupport.map((item) => (
          <li key={item}>
            <a href={`mailto:${item}`} target="_top">
              {item}
            </a>
          </li>
        ))}
      </ul>
      <Typography>Thank you!</Typography>
    </>
  );
}

export default HelpDialogContent;
