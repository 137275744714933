import React from "react";
// eslint-disable-next-line import/no-unresolved
import appConfig from "@regional/config/coreApplication.json";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import moment from "moment";
import { allPaymentMethods } from "../../../../services/global.constant";

export default function ApplicationPageBanner(props) {
  const { applicationData, application } = props;
  const status = applicationData?.status;
  const formProperties = applicationData?.formProperties;
  const officeInfo = appConfig?.officeInfo;
  const displayStatus =
    (appConfig?.aliases && appConfig?.aliases[status]) ?? status;

  return (
    <>
      {(status === "Submitted" ||
        status === "Processing" ||
        status === "Paid") && (
        <Box>
          <Alert role="definition" tabIndex="0" severity="info" elevation={3}>
            {status === "Submitted" && (
              <Box>
                <Typography>
                  Your application has been submitted to the{" "}
                  {officeInfo.officeTitle}.
                  <br />
                </Typography>
                <Typography>
                  Your application reference code is{" "}
                  <strong>
                    {applicationData.applicationFormId
                      ? applicationData.applicationFormId
                      : "Pending"}
                  </strong>
                  . Please retain this for your records.
                </Typography>
                {appConfig.hasTempLicense && (
                  <Typography className="icon">
                    <InfoIcon fontSize="small" style={{ marginRight: "8px" }} />{" "}
                    A temporary registry identification card will be available
                    in a few minutes in the &quot;License Dashboard&quot; link
                    on the left side of the screen. Patients who apply as a
                    self-provider and grow their own medicine are not eligible
                    for a temporary registry identification card. Please check
                    your email for further information.
                  </Typography>
                )}
                <Typography>
                  Application Submission Date :{" "}
                  <strong>
                    {moment(applicationData.dateSubmitted).format(
                      "MM/DD/YYYY h:mm A"
                    )}
                  </strong>
                </Typography>
              </Box>
            )}

            {(status === "Processing" || status === "Paid") && (
              <Typography>
                Your application is processing.
                <br />
                You will be sent a confirmation email within the next hour.
              </Typography>
            )}

            {allPaymentMethods.includes(formProperties.paymentMethod) && (
              <>
                <Typography>
                  Your transaction ID is:{" "}
                  <strong>
                    {formProperties.paymentMethod !== "skipPayment" &&
                      (formProperties.transactionId
                        ? formProperties.transactionId
                        : "Pending")}
                  </strong>
                  <strong>
                    {formProperties.paymentMethod === "skipPayment" &&
                      "Not Applicable"}
                  </strong>
                  <br />
                </Typography>
                {formProperties.transactionToken && (
                  <Typography>
                    Transaction Token:{" "}
                    <strong>{formProperties.transactionToken}</strong>
                  </Typography>
                )}
              </>
            )}

            {!officeInfo.postSubmitMessage && officeInfo.emailAddress && (
              <Typography>
                <InfoIcon fontSize="small" style={{ marginRight: "8px" }} />{" "}
                Your application is submitted and cannot be modified. If you
                want to make changes to your application, please contact the{" "}
                {officeInfo.emailAddress.title} at{" "}
                <a href="mailto:{officeInfo.emailAddress.email}">
                  {officeInfo.emailAddress.email}
                </a>{" "}
                to request that your application be rejected so that you can
                edit it.
              </Typography>
            )}

            {officeInfo.postSubmitMessage && (
              <Typography>{officeInfo.postSubmitMessage}</Typography>
            )}

            <Typography className="icon">
              <InfoIcon fontSize="small" style={{ marginRight: "8px" }} />
              If you do not receive email notifications, please check your spam
              folder.
            </Typography>
          </Alert>
          <Alert
            role="definition"
            tabIndex="0"
            severity="warning"
            elevation={3}
            sx={{ marginTop: "20px" }}
          >
            <Typography>
              The Patient forms have been updated while you have been in
              process. If you are able to submit and subsequently see a red x on
              your submitted application, it will not slow down your application
              review.
            </Typography>
          </Alert>
        </Box>
      )}

      {status === "Denied" && (
        <Alert role="definition" tabIndex="0" severity="warning" elevation={3}>
          <Typography>
            Your application has been denied by the{" "}
            <strong> {officeInfo.officeTitle}.</strong>
          </Typography>
          {applicationData.comment && (
            <Typography>
              Denial Reason : <strong>{applicationData.comment}.</strong>
            </Typography>
          )}
          <Typography>
            Your application reference code is{" "}
            <strong>{applicationData.applicationFormId}.</strong>
          </Typography>
          <Typography>
            Application Submission Date :{" "}
            <strong>
              {moment(applicationData.dateSubmitted).format(
                "MM/DD/YYYY h:mm A"
              )}
            </strong>
          </Typography>
          <Typography>
            Application Denial Date :{" "}
            <strong>
              {moment(applicationData.dateCompleted).format(
                "MM/DD/YYYY h:mm A"
              )}
            </strong>
          </Typography>
        </Alert>
      )}
      {status === "Approved" && (
        <Alert role="definition" tabIndex="0" severity="info" elevation={3}>
          <Typography>
            Congratulations! Your application has been approved by the{" "}
            {officeInfo.officeTitle}.
          </Typography>
          {application?.isLicense && (
            <Typography>
              {" "}
              Your license number is{" "}
              <strong>{applicationData.licenseNumber}.</strong>
            </Typography>
          )}
          {application?.isLicense && (
            <Typography>
              Your license expiration date is{" "}
              <strong>{applicationData.licenseExpiryDate}.</strong>
            </Typography>
          )}
          <Typography>
            Your application reference code is{" "}
            <strong>{applicationData.applicationFormId}.</strong>
          </Typography>
          <Typography>
            Application Submission Date :{" "}
            <strong>
              {moment(applicationData.dateSubmitted).format(
                "MM/DD/YYYY h:mm A"
              )}
            </strong>
          </Typography>
          <Typography>
            Application Approval Date :{" "}
            <strong>
              {moment(applicationData.dateCompleted).format(
                "MM/DD/YYYY h:mm A"
              )}
            </strong>
          </Typography>
        </Alert>
      )}

      {status === "Rejected" && (
        <Alert role="definition" tabIndex="0" severity="warning" elevation={3}>
          <Typography>
            Your application has been {displayStatus} by{" "}
            {officeInfo.officeTitle}. Please check the comments mentioned below
            and re-submit the application.{" "}
          </Typography>
          <Typography>
            Your application reference code is{" "}
            <strong>{applicationData.applicationFormId}.</strong>
          </Typography>
          <Typography>
            {status} Reason : <strong>{applicationData.comment}</strong>
          </Typography>
        </Alert>
      )}
    </>
  );
}

ApplicationPageBanner.propTypes = {
  application: PropTypes.shape({
    isLicense: PropTypes.bool,
  }),
  applicationData: PropTypes.shape({
    status: PropTypes.string.isRequired,
    comment: PropTypes.string,
    applicationFormId: PropTypes.number,
    dateSubmitted: PropTypes.string,
    dateCompleted: PropTypes.string,
    licenseNumber: PropTypes.string,
    licenseExpiryDate: PropTypes.string,
    formProperties: PropTypes.shape({
      paymentMethod: PropTypes.string,
      transactionId: PropTypes.string,
      transactionToken: PropTypes.string,
    }),
  }),
};

ApplicationPageBanner.defaultProps = {
  application: {
    isLicense: false,
  },
  applicationData: {
    comment: "",
    applicationFormId: undefined,
    dateSubmitted: "",
    dateCompleted: "",
    licenseNumber: "",
    licenseExpiryDate: "",
    formProperties: {
      paymentMethod: "",
      transactionId: "",
      transactionToken: "",
    },
  },
};
