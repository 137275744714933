/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import classes from "../../../../theme/theme.module.css";

function TermsConditions() {
  return (
    <Grid
      container
      spacing={2}
      sx={{ "& .MuiTypography-root,strong": { fontSize: "0.88rem" } }}
    >
      <Grid item xs={12}>
        <Typography>
          PLEASE READ ALL OF THE FOLLOWING TERMS AND CONDITIONS CAREFULLY AS
          THEY CONTAIN INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES, AND
          OBLIGATIONS. THESE INCLUDE VARIOUS LIMITATIONS AND EXCLUSIONS, AS WELL
          AS OBLIGATIONS TO COMPLY WITH APPLICABLE LAWS AND REGULATIONS
        </Typography>
        <br />
      </Grid>
      <Grid item>
        <Typography>
          THIS AGREEMENT <strong>(“AGREEMENT”)</strong> IS A LEGALLY BINDING
          AGREEMENT BETWEEN COMPLIA LLC, A COLORADO LIMITED LIABILITY COMPANY{" "}
          <strong>("COMPLIA")</strong> AND STATE OF WEST VIRGINIA BY AND THROUGH
          WEST VIRGINIA STATE DEPARTMENT OF HEALTH{" "}
          <strong>("YOU" OR "CLIENT")</strong> FOR ACCESS TO AND USE OF
          COMPLIA'S ONLINE COMPLIANCE MANAGEMENT PLATFORM AND RELATED SERVICES
          (COLLECTIVELY, THE <strong>“SERVICES”)</strong> THAT PROVIDE PRIVATE
          BUSINESSES AND GOVERNMENT AGENCIES THE TOOLS NEEDED TO TRACK AND
          MANAGE REGULATORY COMPLIANCE MATTERS AND, IN THE CASE OF GOVERNMENT
          AGENCIES AS CLIENTS (EACH, A <strong>“GOVERNMENT CLIENT”)</strong>, TO
          CONDUCT AND MANAGE THE LICENSING OF PRIVATE BUSINESSES SEEKING TO
          PARTICIPATE IN MEDICAL MARIJUANA PROGRAMS{" "}
          <strong>(“PROGRAMS”)</strong>, IN EACH CASE ONLY ON THE CONDITION THAT
          YOU ACCEPT AND AGREE TO COMPLY WITH ALL OF THE TERMS AND CONDITIONS OF
          THIS AGREEMENT. IF YOU ARE ACCESSING THE SERVICES ON BEHALF OF A
          COMPANY, GOVERNMENT AGENCY, OR OTHER ENTITY, THE TERM{" "}
          <strong>"YOU"</strong>, <strong>"YOUR"</strong>, OR{" "}
          <strong>"CLIENT"</strong> REFERS TO THAT ENTITY, AND REFERENCES TO{" "}
          <strong>“CLIENT”</strong> INCLUDE GOVERNMENT CLIENTS.
        </Typography>
      </Grid>
      <Grid item>
        <Typography>The parties hereby agree as follows: </Typography>
        <br />
        <Typography>
          <span>
            <b>
              ARTICLE 1—LICENSE TO USE SERVICES; UPGRADES, MAINTENANCE AND
              SUPPORT{" "}
            </b>
          </span>
          <br />
          <span>
            <b className={classes.ml10}>1.1 Access Rights.</b> Complia hereby
            grants Client, during the term of the Master Agreement a limited,
            revocable, non-transferable and non-exclusive license for Client’s
            employees <strong>(“Authorized Users”)</strong> to use the Services
            in accordance with the use parameters described herein, solely for
            Client’s internal business purposes consistent with the terms and
            conditions of this Agreement; provided that Government Clients may
            use the Services on a patient-facing basis consistent with this
            Agreement. The use of the Services is solely for purposes of
            Client’s management, review and processing of regulatory filings and
            other compliance-related purposes, and, in the case of Government
            Clients, to conduct and manage the licensing of private businesses
            as stated above.
          </span>
          <br />
          <span>
            <b className={classes.ml10}>1.2 Restrictions.</b> All rights not
            expressly granted to Client in this Agreement are retained and
            reserved by Complia. Client may not access or use the Services for
            any other purposes or in connection with third party applications,
            software, or programs not provided by Complia, except to interface
            with providers or recipients of data regarding Programs with
            Complia’s prior written consent, which shall not be unreasonably
            withheld, conditioned or delayed. In addition, Client shall not
            knowingly (i) license, sublicense, sell, resell, transfer, assign,
            distribute, or otherwise commercially exploit or make available to
            any third party the Services or the content in any way; (ii) modify
            or make derivative works (other than application and licensing
            documents) based upon the Services or the content; (iii) with the
            exception of establishing a link on Client’s website for the
            Programs, create Internet “links” to the Services or “frame” or
            “mirror” any content on any other server or wireless or
            Internet-based device; or (iv) reverse engineer or access the
            Service in order to (a) build a competitive product or service, (b)
            build a product using similar ideas, features, functions or graphics
            of the Services, or (c) copy any ideas, features, functions or
            graphics of the Services. User licenses cannot be shared or used by
            more than one individual user but may be reassigned from time to
            time to new users who are replacing former users who have changed
            job status or function and no longer use the Services.
          </span>
          <br />
          <span>
            <b className={classes.ml10}>
              1.3. Services Upgrades, Maintenance and Support
            </b>{" "}
            During the term of this Agreement, Client shall be entitled to web
            application software upgrades as provided in the sole discretion of
            Complia. Upgrades shall be limited to the specific edition of the
            Services for which the Client is licensed. Client may purchase
            additional Services at any time during the term if and when Complia
            makes any such additional Services available to Client. Any
            additional Services purchased or obtained by Client during the term
            shall be subject to the all terms and conditions of this Agreement
            or any other relevant Complia agreement that shall be negotiated by
            Complia and Client before becoming applicable to the additional
            Services. During the term, Complia shall be responsible for
            providing maintenance and support for the software embodied in the
            Services. All items delivered by Complia in providing such support,
            including error corrections and upgrades, shall be deemed part of
            the Services and shall be subject to all terms and conditions of
            this Agreement. Routine support for the Services is provided to
            Clients and is available via: (i) email to support@mycomplia.com and
            (ii) support tickets submitted through Complia emails. Support does
            not cover (and Complia is not responsible for) issues arising from:
            (i) Client equipment, software, network connections or other
            infrastructure; (ii) use of the Services by Client in a manner not
            consistent with its documentation, (iii) modifications to the
            Service by any party other than Client (except for reasonable
            modifications made to all similarly situated participants under the
            OMES program), (iv) the acts, services or systems of third parties
            not part of solutions provided by Complia pursuant to the Contract
            or (v) general Internet problems.
          </span>
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          <span>
            <b>ARTICLE 2—CLIENT RESPONSIBILITIES</b>{" "}
          </span>
          <br />
          <span>
            <b className={classes.ml10}>2.1 Client Responsibilities.</b>Client
            is solely responsible for all activity occurring under Client’s user
            accounts and shall abide by all applicable local, state, national,
            and foreign, laws, treaties and regulations in connection with
            Client’s use of the Services, including those related to data
            privacy, international communications, and the transmission of
            technical or personal data. Client shall: (i) notify Complia
            immediately of any unauthorized use of any password or account or
            any other known or suspected breach of security; (ii) report to
            Complia immediately and use reasonable efforts to stop immediately
            any copying or distribution of Content that is known or suspected by
            Client or Client users; and (iii) not impersonate another Complia
            user or provide false identity information to gain access to or use
            the Services.{" "}
          </span>
          <br />
          <span>
            <b className={classes.ml10}>2.2 Client Warranty</b> Client
            represents, warrants and covenants to Complia that: (a) it has the
            authority to enter into this Agreement and perform its obligations
            hereunder; and (b) it and its Authorized Users will only use the
            Services for lawful purposes and will not knowingly violate any law
            of any country or the intellectual property rights of any third
            party incident to the use of the Services. Client represents and
            warrants that Client has not falsely identified Client nor knowingly
            provided any false information to gain access to the Services and
            that Client’s billing information is correct. Client further
            represents and warrants that it owns, or has obtained all necessary
            third party licenses and permissions to use, the Client Data
            provided hereunder.
          </span>
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          <span>
            <b>ARTICLE 3—COMPLIA RESPONSIBILITIES; LIMITED WARRANTIES</b>{" "}
          </span>
          <br />
          <span>
            <b className={classes.ml10}>3.1 Complia Responsibilities. </b>{" "}
            Complia shall comply with the laws and regulations of the
            jurisdiction of Government Client, and except with respect to
            compliance with applicable law, shall comply with all directives and
            instructions issued by such Government Client, to the extent
            consistent with the terms and conditions applicable to the Services
            and set forth in this Agreement. Complia represents and warrants to
            each Client that (i) it has the legal power and authority to enter
            into this Agreement; (ii) it will use commercially reasonable
            efforts to provide the Services in a manner consistent with general
            industry standards reasonably applicable to the provision thereof;
            and (iii) that the Services will perform in accordance with the
            online Complia help documentation, which will be provided after
            scope is identified, under normal use and circumstances.{" "}
          </span>
          <br />
          <span>
            {" "}
            <b className={classes.ml10}>3.2 Software Security. </b> Complia
            shall implement industry-standard security measures to protect
            Client’s computer systems, network devices and/or the data processed
            thereon against the risk of penetration by, or exposure to, a third
            party. No personal information can be stored, saved or transmitted
            beyond what is expressly allowed pursuant to this Agreement.
          </span>
          <br />
          <span>
            {" "}
            <b className={classes.ml10}>
              3.3 Complia Warranty; Disclaimer.{" "}
            </b>{" "}
            COMPLIA NEITHER WARRANTS THAT THE SOFTWARE WILL BE UNINTERRUPTED OR
            ERROR FREE.COMPLIA MAKES NO OTHER WARRANTIES, EXPRESS OR IMPLIED,
            INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF
            MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE IN RELATION TO
            THE SOFTWARE. COMPLIA MAKES ABSOLUTELY NO WARRANTIES WITH REFERENCE
            TO THIRD PARTY SOFTWARE AND/OR SERVICES USED ALONG WITH THE SOFTWARE
            THAT WERE NOT PROVIDED BY COMPLIA PURSUANT TO THIS AGREEMENT.
          </span>
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          <span>
            <b>ARTICLE 4—LIMITATION OF LIABILITY</b>{" "}
          </span>
          <br />
          <span>
            IN NO EVENT SHALL COMPLIA’S AGGREGATE LIABILITY EXCEED THE AMOUNT OF
            $120,000.00 for any and all claims arising in the initial term. This
            $120,000.00 limit shall reset for each subsequent 12 month renewal
            term for any and all claims arising in that renewal term. EITHER
            PARTY SHALL, BE LIABLE TO THE OTHER PARTY FOR ANY INDIRECT,
            INCIDENTAL, CONSEQUENTIAL, SPECIAL OR PUNITIVE DAMAGES FOR ANY
            MATTER ARISING FROM OR RELATING TO THIS AGREEMENT, THE
            SOFTWARE/SERVICES, INCLUDING, WITHOUT LIMITATION, USER'S USE OR
            INABILITY TO USE THE SOFTWARE/SERVICES, , DELAY, FAILURE, OR
            ALTERATION OF ANY TRANSMISSION OF DATA OR ANY DATA OR MATERIAL FROM
            A THIRD PARTY ACCESSED ON OR THROUGH THE SOFTWARE/SERVICES NOT
            PROVIDED BY COMPLIA PURSUANT TO THIS AGREEMENT, WHETHER SUCH
            LIABILITY IS ASSERTED ON THE BASIS OF CONTRACT, TORT OR OTHERWISE
            AND EVEN IF COMPLIA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH.
          </span>
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          <span>
            <b>ARTICLE 6—PROPRIETARY RIGHTS; INDEMNITY</b>{" "}
          </span>
          <br />
          <span>
            <b className={classes.ml10}> 6.1 Proprietary Rights. </b>Except for
            the license granted in <strong>Section 1.1</strong>, and except for
            the data input into the system by Client, no right title or interest
            of discoveries, inventions, patent applications, improvements,
            techniques, processes, copyrights, trade secrets, know-how and other
            intellectual property or other proprietary rights in and to the
            Services made available under this Agreement is transferred to
            Client hereunder. Complia and any of its third party licensors
            retain all right, title and interests, including, without
            limitation, all copyright and other proprietary rights in and to the
            Services and Complia Data and work performed in relation to this
            Agreement. Subject to <strong>Article 8</strong>, Client will retain
            all right, title and interest to the data and documents created by
            Client using the Services. Except as expressly stated herein, this
            Agreement shall not affect either party’s present or future rights
            under any country’s patent laws.
          </span>
          <br />
          <span>
            <b className={classes.ml10}>
              {" "}
              6.2 Account Information and Client Data.{" "}
            </b>
            Client shall own all right, title, and interest in and to the Client
            Data. Notwithstanding the foregoing, Client grants Complia a
            limited, non-exclusive license during the term to use the Client
            Data for purposes of providing the Services (including any new
            Services Complia may add, in its discretion, during the term
            pursuant to this Agreement). Client must agree to such new Services
            prior to Complia’s provision of same. Client, not Complia, shall
            have sole responsibility for the accuracy, quality, integrity,
            legality, reliability, appropriateness, or right to display, share,
            or otherwise use all Client Data, and Complia shall not under any
            circumstances be responsible or liable for Client’s upload and use
            of Client Data on the Services. In the event this Agreement is
            terminated for any reason, Complia will permanently lay dormant any
            Client Data within ninety (90) days following termination and
            available for termination upon client request. Certain features of
            the Services may enable Client to use the Services to transfer
            Client Data directly to a third party, such as transmitting
            regulatory compliance data, upon notification to the client. In the
            event that Client elects to utilize such features, Client hereby
            expressly authorizes Complia to effect transfers of Client Data to
            the indicated third party, as reasonably related to implementation
            of the requested functionality. Moreover, Client authorizes the
            indicated third party to utilize and maintain a copy of the
            transferred Client Data for the purposes for which it was
            transferred.
          </span>
          <br />
          <span>
            <b className={classes.ml10}>6.4 Client Documents. </b>
            Notwithstanding the foregoing, Client grants Complia a
            non-exclusive, nontransferable, non-sublicenseable license to use
            the Client Documents (as defined herein) during the term for
            purposes of providing the Services. In the event this Agreement is
            terminated (other than by reason of Client’s breach), Complia will
            make available to Client the Client Documents for a period of ninety
            (90) days following termination. After such ninety (90) day period,
            Complia shall permanently delete or lay dormant all Client Documents
            in its possession. Upon termination for cause, Client’s right to
            access or use Client Documents immediately ceases, and Complia shall
            have no obligation to maintain or forward any Client Documents to
            Client, and Complia shall permanently delete or lay dormant all
            Client Documents in its possession after ninety(90) days.
            <strong>"Client Documents"</strong> means any documents and
            associated intellectual property generated or created for Client
            through the Services, including but not limited to required
            licensing documents, government filings, and other regulatory
            materials created through the Services, data and analytics related
            to the Client Data, and any other metrics, content or data derived
            through the Services.
          </span>
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          <span>
            <b>ARTICLE 7 </b>{" "}
          </span>
          <br />
          {/* <span ><b>7.1 Payment. </b>Except as otherwise set forth in the
Contract, Client shall pay all fees as set forth in the
SHI Quote.</b> </span><br/>
      <span ><b>7.2 Survival. </b>Any provisions necessary to
interpret the respective rights and obligations of the
parties hereunder shall survive any termination or
expiration of this Agreement, regardless of the cause
of such termination or expiration.
          <b/> </span> */}
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          <span>
            <b>
              ARTICLE 8 – ADDITIONAL RIGHTS OF GOVERNMENT CLIENTS; SAVINGS
              CLAUSE
            </b>{" "}
          </span>
          <br />
          <span>
            The laws and regulations of the jurisdictions of Government Clients
            may limit the enforceability of certain terms and conditions set
            forth in this Agreement, including limitations on the type and
            amount of damages recoverable by Government Client. If any provision
            of this Agreement is held to be unenforceable, in whole or in part,
            such holding will not affect the validity of the other provisions of
            this Agreement, with respect to any Client.
          </span>
        </Typography>
      </Grid>
      <Grid item>
        {" "}
        <Typography>
          <span>
            <b>ARTICLE 9—GENERAL PROVISIONS </b>{" "}
          </span>
          <br />
          <span>
            Any notice required or permitted to be delivered pursuant to this
            Agreement shall be in writing to the contacts identified in Master
            Agreement, Section IX or Master Agreement Exhibit A, Section VII, as
            applicable. The failure of either party to enforce, or the delay by
            either party in enforcing, any of its rights under this Agreement
            will not be deemed to be a waiver or modification by such party of
            any of its rights under this Agreement. The headings used herein are
            for reference and convenience only and shall not enter into the
            interpretation hereof. No purchase order, other ordering document or
            any hand written or typewritten text which purports to modify or
            supplement the printed text of this Agreement or any Order Form
            shall add to or vary the terms of this Agreement. All such proposed
            variations or additions (whether submitted by Complia or Client) are
            objected to and shall have no force or effect.
          </span>
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          <i>
            <small>Terms and Conditions 1.0; Published on August 2018</small>
          </i>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default TermsConditions;
